import Table from 'design-system/components/Table';
import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

function SuperHotTag() {
  return (
    <Tag
      icon={<Icon glyph="superHot" />}
      variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}
      size={TAG_SIZE.SMALL}
    >
      <FormattedMessageWithValidation id="enqpage_table_hotness_super_hot" />
    </Tag>
  );
}

function HotTag() {
  return (
    <Tag
      icon={<Icon glyph="hot" />}
      variant={TAG_VARIANT.COMPLEMENTARY_ORANGE}
      size={TAG_SIZE.SMALL}
    >
      <FormattedMessageWithValidation id="enqpage_table_hotness_hot" />
    </Tag>
  );
}

export default function HotnessColumn(props) {
  const { value } = useColumn(props);
  const hotnessByTag = {
    superHot: <SuperHotTag />,
    hot: <HotTag />,
  };
  return <Table.Cell>{hotnessByTag[value] ?? <div />}</Table.Cell>;
}
