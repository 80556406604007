import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { TABLE_ALIGNMENT } from 'design-system/components/Table';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { dispatch } from 'reffects';
import { DataTable, TextColumn } from '../../../../../partials/DataTable';
import LeadsStats from '../../LeadsStats';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import StageFilters from '../../StageFilters/StageFilters';
import StatusColumn from '../StatusColumn';
import AdFieldsColumn from './AdFieldsColumn';
import PropertyInterestedInSnippet from '../PropertyInterestedInSnippet';
import AccumulatedPropertiesSnippet from '../AccumulatedPropertiesSnippet';
import buildTableRowEventsRedirectingTo from '../../../../../partials/DataTable/utils/buildTableRowEventsRedirectingTo';
import DateFilters from '../../DateFilters/DateFilters';
import ClearFiltersButton from '../../../../../partials/DataTable/components/filters/ClearFiltersButton';
import { LeadsFilter } from '../../LeadsFilter/LeadsFilter';
import PhoneColumn from '../PhoneColumn';
import { LastActivityColumn } from '../LastActivityColumn';
import LeadsTableSkeleton from './LeadsTableSkeleton';
import { UnreadColumn } from '../UnreadColumn';
import LeadsPropertyFilter from './LeadsPropertyFilter/LeadsPropertyFilter';
import { eligibleLeadsForLeadPlusSelector } from '../selectors';
import EmailColumn from '../EmailColumn';
import { TRACK_CLICK } from '../../../../../partials/Tracking/events';
import { useFeatureSelector } from '../../../../../utils/featuresFlags/selectors';
import HotnessColumn from '../HotnessColumn';
import ContactColumn from '../ContactColumn';

const Filters = styled.div`
  display: flex;
  flex-direction: row;

  ${spacing.stack(SPACING_SIZE.L)}
  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const EnquiriesCount = styled(Body1)`
  line-height: 38px;
`;

const LeadPlusColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XS)};
`;

const LeadPlusText = styled.span`
  display: inline;
`;

const Badge = styled.div`
  ${Tag} {
    text-transform: capitalize;
  }
`;

function NewTable() {
  const eligibleLeadsForLeadPlus = useSelector(
    eligibleLeadsForLeadPlusSelector
  );
  const eligibleLeadsForQualification = {};
  const handleHeaderClick = (columnName) => {
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: `${columnName}-column`,
        sectionName: 'enquiries-list-ordination-agency',
      },
    });
  };
  return (
    <DataTable
      allowPagination
      clickableRows
      rowEvents={buildTableRowEventsRedirectingTo(
        (itemId) => `/leads/${itemId}`
      )}
      loadingComponent={<LeadsTableSkeleton />}
      noItemsMessage={
        <FormattedMessageWithValidation id="enqpage_table_empty_results" />
      }
    >
      <HotnessColumn
        id="hotness"
        title={<FormattedMessageWithValidation id="enqpage_table_hotness" />}
      />
      <StatusColumn
        id="status"
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_status" />}
        data-test="lead-status"
        onClick={() => handleHeaderClick('status')}
      />
      <ContactColumn
        id="contact"
        source={{
          id: 'id',
          name: 'name',
          email: 'email',
          hasVerifiedEmail: 'hasVerifiedEmail',
          phone: 'phone',
          hasVerifiedPhone: 'hasVerifiedPhone',
        }}
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_contact" />}
        data-test="lead-contact"
        eligibleLeadsForLeadPlus={eligibleLeadsForLeadPlus}
        eligibleLeadsForQualification={eligibleLeadsForQualification}
        onClick={() => handleHeaderClick('name')}
      />
      <AdFieldsColumn
        id="properties"
        title={<FormattedMessageWithValidation id="enqpage_table_property" />}
        renderField={({ operationType, title, address, image }) => (
          <PropertyInterestedInSnippet
            operationType={operationType}
            title={title}
            address={address}
            image={image}
          />
        )}
        renderAccumulatedField={({ total }) => (
          <AccumulatedPropertiesSnippet total={total} />
        )}
      />
      <AdFieldsColumn
        id="enquiryCount"
        title={<FormattedMessageWithValidation id="enqpage_table_enquiry" />}
        renderField={renderEnquiryCount}
        renderAccumulatedField={renderEnquiryCount}
        alignment={TABLE_ALIGNMENT.CENTER}
      />
      <LastActivityColumn
        id="lastActivity"
        source={{
          date: 'lastActivity',
          type: 'lastActivityType',
          callStatus: 'lastActivityCallStatus',
          callDurationInSeconds: 'lastActivityCallDurationInSeconds',
          isFromPersonalShopper: 'isFromPersonalShopper',
        }}
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_date" />}
        onClick={() => handleHeaderClick('last-activity')}
      />
      <UnreadColumn
        id="unread"
        source={{
          isUnread: 'isUnread',
        }}
      />
    </DataTable>
  );
}

function OldTable() {
  const eligibleLeadsForLeadPlus = useSelector(
    eligibleLeadsForLeadPlusSelector
  );

  const renderLeadPlus = ({ id, name }) => (
    <LeadPlusColumn>
      <LeadPlusText>{name}</LeadPlusText>
      <Badge hidden={!eligibleLeadsForLeadPlus[id]}>
        <Tooltip
          title={
            <FormattedMessageWithValidation id="lead_plus_badge_tooltip" />
          }
          $placement={TOOLTIP_PLACEMENT.RIGHT}
        >
          <Tag variant={TAG_VARIANT.GOLD}>
            <FormattedMessageWithValidation id="lead_plus_badge" />
          </Tag>
        </Tooltip>
      </Badge>
    </LeadPlusColumn>
  );

  const handleHeaderClick = (columnName) => {
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: `${columnName}-column`,
        sectionName: 'enquiries-list-ordination-agency',
      },
    });
  };
  return (
    <DataTable
      allowPagination
      clickableRows
      rowEvents={buildTableRowEventsRedirectingTo(
        (itemId) => `/leads/${itemId}`
      )}
      loadingComponent={<LeadsTableSkeleton />}
      noItemsMessage={
        <FormattedMessageWithValidation id="enqpage_table_empty_results" />
      }
    >
      <TextColumn
        id="name"
        source={{
          id: 'id',
          name: 'name',
        }}
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_name" />}
        renderField={renderLeadPlus}
        data-test="lead-name"
        onClick={() => handleHeaderClick('name')}
      />
      <EmailColumn
        id="email"
        source={{ email: 'email', hasVerifiedEmail: 'hasVerifiedEmail' }}
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_email" />}
        onClick={() => handleHeaderClick('email')}
      />
      <PhoneColumn
        id="phone"
        source={{ phone: 'phone', hasVerifiedPhone: 'hasVerifiedPhone' }}
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_phone" />}
        onClick={() => handleHeaderClick('phone')}
      />
      <StatusColumn
        id="status"
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_status" />}
        data-test="lead-status"
        onClick={() => handleHeaderClick('status')}
      />
      <AdFieldsColumn
        id="properties"
        title={<FormattedMessageWithValidation id="enqpage_table_property" />}
        renderField={({ operationType, title, address, image }) => (
          <PropertyInterestedInSnippet
            operationType={operationType}
            title={title}
            address={address}
            image={image}
          />
        )}
        renderAccumulatedField={({ total }) => (
          <AccumulatedPropertiesSnippet total={total} />
        )}
      />
      <AdFieldsColumn
        id="enquiryCount"
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_enquiry" />}
        renderField={renderEnquiryCount}
        renderAccumulatedField={renderEnquiryCount}
        alignment={TABLE_ALIGNMENT.CENTER}
        onClick={() => handleHeaderClick('enquiries-count')}
      />
      <LastActivityColumn
        id="lastActivity"
        source={{
          date: 'lastActivity',
          type: 'lastActivityType',
          callStatus: 'lastActivityCallStatus',
        }}
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_date" />}
        onClick={() => handleHeaderClick('last-activity')}
      />
      <UnreadColumn
        id="unread"
        source={{
          isUnread: 'isUnread',
        }}
      />
    </DataTable>
  );
}

function AgencyLeadsTable() {
  const NEW_INBOX_4238 = useFeatureSelector('NEW_INBOX_4238');
  const handleFilterClick = (filterName, payload) => {
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: `filter-enquiries-by-${filterName}`,
        sectionName: 'enquiries-list-filters-agency',
        payload,
      },
    });
  };

  return (
    <>
      <Filters>
        <LeadsFilter
          onSelect={() => {
            handleFilterClick('lead');
          }}
        />
        <LeadsPropertyFilter
          onSelect={() => {
            handleFilterClick('property');
          }}
        />
        <StageFilters
          onSelect={(payload) => {
            handleFilterClick('stage', payload);
          }}
        />
        <DateFilters
          onSelect={(payload) => {
            handleFilterClick('date', payload);
          }}
        />
        <ClearFiltersButton />
      </Filters>
      <LeadsStats />
      {NEW_INBOX_4238 ? <NewTable /> : <OldTable />}
    </>
  );
}

function renderEnquiryCount({ enquiriesCount }) {
  return <EnquiriesCount>{enquiriesCount}</EnquiriesCount>;
}

export default AgencyLeadsTable;
